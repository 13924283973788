import { RegistrationForm } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'

const AccountCreationPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const { language } = useLanguage()

  const goToForgetPassword = useCallback(() => {
    navigate(getLocalizedRoutesForHistoryPush('forgotPasswordStart'))
  }, [navigate])

  const goToLogin = useCallback(() => {
    navigate(getLocalizedRoutesForHistoryPush('login'))
  }, [navigate])

  return (
    <LayoutCenter>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.createAccount}`} />
        </Helmet>
      )}

      <RegistrationForm
        baseUrl={window.brandConfig.baseUrl}
        language={language}
        goToForgetPassword={goToForgetPassword}
        ppLink={window.brandConfig.privacyPolicyUrl}
        tosLink={window.brandConfig.termsOfServiceUrl}
        toLoginLink={getLocalizedRoutesForHistoryPush('login')}
        navigateToNextScreen={goToLogin}
        resendLink={getLocalizedRoutesForHistoryPush('resendCode')}
      />
    </LayoutCenter>
  )
}

export default AccountCreationPage
