import { Layout } from 'antd'
import { Cookie, GGPVerticalLogo } from '@myportal/ecedo-myportal-frontend-components'
import React, { FunctionComponent, ReactNode, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import useLanguage from '../hooks/useLanguage'
import { getLocalizedRoutesForHistoryPush } from '../utils'
import BackgroundContainer from './BackgroundContainer.tsx'
import PrimaryBackground from './PrimaryBackground.tsx'

interface LayoutCenterProps {
  logoVisible?: boolean
  top?: boolean
  children?: ReactNode
}

const FullLayout = styled(({ top, ...props }) => <Layout {...props} />)`
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props: { top: boolean }): string => (props.top ? 'flex-start' : 'center')};
  position: relative;
  padding: 48px 16px;
  overflow: hidden;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 24px;
  width: 150px;
  position: relative;
  z-index: 2;
  svg {
    width: 100%;
    height: 100%;
    max-width: 150px;
    max-height: 80px;
    object-fit: contain;
  }
`

const LayoutCenter: FunctionComponent<LayoutCenterProps> = ({ logoVisible = true, top = false, children }) => {
  const { language } = useLanguage()
  const navigate = useNavigate()

  const handleMoreInfo = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      navigate(getLocalizedRoutesForHistoryPush('privacyPolicy'))
    },
    [navigate]
  )
  return (
    <FullLayout top={top}>
      {logoVisible && (
        <LogoContainer>
          <Link to="/">
            <GGPVerticalLogo />
          </Link>
        </LogoContainer>
      )}
      <BackgroundContainer>
        <PrimaryBackground />
      </BackgroundContainer>
      {children}
      <Cookie
        handleMoreInfo={handleMoreInfo}
        googleTagManagerId={window.brandConfig.googleTagManagerId}
        googleAnalyticsId={window.brandConfig.googleAnalyticsId}
        cookieExpires={window.brandConfig.cookieExpires}
        language={language}
      />
    </FullLayout>
  )
}

export default LayoutCenter
