import { FunctionComponent } from 'react'

interface PrimaryBackgroundProps {
  height?: string
  width?: string
}

const PrimaryBackground: FunctionComponent<PrimaryBackgroundProps> = ({ width = '100%', height = '100%' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width={width} height={height} preserveAspectRatio="none">
      <polygon points="70,35 65,0 0,0 0,100 10,100" fill="#002776" />
      <polygon points="70,35 10,100 80,100" fill="#FF6319" />
      <polygon points="70,35 98,0 100,0 100,100 80,100" fill="#b9d9eb" />
      <polygon points="70,35 65,0 98,0" fill="white" />
    </svg>
  )
}

export default PrimaryBackground
