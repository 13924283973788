import { Login as LoginForm } from '@myportal/ecedo-myportal-frontend-components'
import { FunctionComponent, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import Cookie from 'js-cookie'

import LayoutCenter from '../components/LayoutCenter'
import useLanguage from '../hooks/useLanguage'
import { getLocalizedRoutesForHistoryPush, localizedRoutes } from '../utils'
import { Alert as AntdAlert } from 'antd'
import styled from 'styled-components'

const Alert = styled(AntdAlert)`
  max-width: 450px;
  width: 100%;
`

const LoginPage: FunctionComponent = () => {
  const { language } = useLanguage()
  const navigate = useNavigate()

  const navigateToForgotPassword = useCallback(() => {
    navigate(getLocalizedRoutesForHistoryPush('forgotPasswordStart'))
  }, [navigate])

  const navigateToCreateAccount = useCallback(() => {
    navigate(getLocalizedRoutesForHistoryPush('createAccount'))
  }, [navigate])

  const navigateToNewPassword = useCallback(() => {
    navigate(getLocalizedRoutesForHistoryPush('newPassword'))
  }, [navigate])

  const navigateToHome = useCallback(() => {
    navigate('/')
  }, [navigate])

  const navigateToConfirm = useCallback(() => {
    navigate(getLocalizedRoutesForHistoryPush('confirmAccount'))
  }, [navigate])

  const passwordChangeAlreadyRequested = Cookie.get('privacy-and-security-update')

  return (
    <LayoutCenter logoVisible={false}>
      {window.brandConfig.languageUrl === 'both' && (
        <Helmet>
          <link rel="canonical" href={`${window.brandConfig.baseUrl}${localizedRoutes.nl.login}`} />
        </Helmet>
      )}
      {!passwordChangeAlreadyRequested && window.brandConfig.changePassword && <Alert type="warning" message={window.brandConfig.changePassword} showIcon />}
      <LoginForm
        language={language}
        navigateToForgotPassword={navigateToForgotPassword}
        navigateToCreateAccount={navigateToCreateAccount}
        navigateToHome={navigateToHome}
        navigateToNewPassword={navigateToNewPassword}
        navigateToConfirm={navigateToConfirm}
      />
    </LayoutCenter>
  )
}

export default LoginPage
